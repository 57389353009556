import React, { Component } from 'react';
import {  BrowserRouter, Route, Switch,HashRouter } from 'react-router-dom';
import './App.css';
import './customStyle.css';
import './scss/style.scss';
import {Redirect} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Cookies from 'universal-cookie';
import { LocalizeProvider } from 'react-localize-redux';
// Pages
import { Login, Report, ReportsViewer,ReportsCripted,Register } from './viewsStandard/Pages/index';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const cookies = new Cookies();

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));


const PrivateRoute =({ component: Component, ...rest }) => (
  <Route
    {...rest}
   
    render={props =>
      cookies.get('JEProjectJWTTK') !==undefined ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
class App extends Component {

  render() {
    return (
      <LocalizeProvider >
      <React.Suspense fallback={loading}>
      <BrowserRouter  >
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Registrazione" component={Register} />
          <Route exact path="/reports" name="Report" component={Report} />
          <Route exact path="/ReportsCripted" name="ReportsCripted" component={ReportsCripted} />
          <Route exact path="/reportsViewer" name="ReportsViewer" component={ReportsViewer} />
          <PrivateRoute  key="privateRoot" path="/" name="AreaRiservata" component={TheLayout} />
        </Switch>
      </BrowserRouter>
     </React.Suspense>
      </LocalizeProvider>
    );
  }
}

export default App;
