import React, { Component } from 'react';
import {getUrlApi,getDBCONF,getMENUCONF} from "../../../helpers/Helpers";
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { Button, Card, CardBody, CardHeader, Col, Container, Jumbotron, Row } from 'reactstrap';
const cookies = new Cookies();
class ReportsViewer extends Component {
  
  constructor(props){
    super(props);
    this.state = {
        error:1
    }
    
   }


  getReportViewer = async() =>{
    let token =  cookies.get('JEProjectJWTTK'); 
    const values = queryString.parse(this.props.location.search);
    let strPar=decodeURI(values.pars);
   
 
    if(strPar!==null || strPar!==undefined || strPar!==""){
        let pardecript= getMENUCONF(strPar);
        let jsonParameters=JSON.parse(decodeURI(pardecript));
        
        let parameters=jsonParameters.parametri;
        let nomeRapportino=jsonParameters.reportName;
        let logdetails = jsonParameters.logdetails;
        const self = this;
        let body={
          parameters:parameters,
          reportName:nomeRapportino,
          conf:getDBCONF(),
          token:token,
          logdetails:logdetails
        };
        let response = await fetch(getUrlApi()+"/api/getReport", {
          method: 'POST',
          body:   JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' },
        }).then(function(response) {
            if(response.ok) {
              return response.blob();
            }
            throw new Error('Network response was not ok.');
          }).then(function(myBlob) { 
            const file = new Blob([myBlob], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            var link = document.createElement("a");
            link.href = fileURL;
            link.style = "visibility:hidden";
            link.download = 'report';
            document.body.appendChild(link);
            //link.click();
            document.body.removeChild(link);
            //window.location.href =fileURL;
            //window.open(file, '_self');
            //Build a URL from the file
            //Open the URL on new Window
            HTMLMediaElement.srcObject = fileURL;
            window.open(fileURL);
            self.setState({
              error: 2
            });
          })
          .catch(function(error) {
            self.setState({
              error: 100
            });
          }); 
          return response;
     
    
    
      };
    }

      componentDidMount(){
        this.getReportViewer();
      }
      render() {
        
        return (
          <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                {this.state.error==1?
                  <span className="clearfix">
                    <h1 className="float-left display-3 mr-4">Report in fase di download!</h1>
                    <h4 className="pt-3"></h4>
                    <p className="text-muted float-left">Attendere il completamento.</p>
                  </span>
                  :
                  this.state.error==2?
                      <span className="clearfix">
                        <h1 className="float-left display-3 mr-4">Report scaricato!</h1>
                        <h4 className="pt-3"></h4>
                        <p className="text-muted float-left">Se non riesci a vedere il report, controlla in alto a destra del tuo browser(chrome,firefox, internet explorer ecc.)
                          che non ci siano dei blocchi(blocco popup).
                          In tal caso ricordati di consentire sempre i popup da questo sito. 
                          Una volta salvato ricarica la pagina.<br/>
                          Se hai problemi, CONTATTACI!</p>
                      </span>
                      :
                      <span className="clearfix">
                        <h1 className="float-left display-3 mr-4">Errore in fase di generazione report!</h1>
                        <h4 className="pt-3"></h4>
                        <p className="text-muted float-left">Richiedi il report nuovamente, in quanto la fase di generazione link ha avuto problemi.<br/>
                          Ci scusiamo per il problema!</p>
                      </span>
                  
                }
               
                
              </Col>
            </Row>
          </Container>
        </div>
         
        );
      }
    }
export default ReportsViewer;