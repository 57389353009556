export const sygnet = ['32 32', `
  <title>Novalinea</title>
  <image id="image0" width="32" height="32" x="0" y="0"
    xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAn1BMVEW4UBa5UBbBdVzdvbPn
08/z5+Pz6efz5+Tn087dvLTBc1jgxbz//v3////+/fzgxL3hx8Hv5ODTqJ3JjHzJi3zKi3zJjH3U
qZ7w5ODgxr/Ggmz+///jycDlzsf//v7FfWffw7z37+y5URe4URn48vDfwbrt3tnky8Tm0Mrr2tXY
tq3cvrXz6eby6efYtazbvbTp1c/z6OXTp5vVrqH06eUoikbfAAAAAWJLR0QN9rRh9QAAAAd0SU1F
B+QJAgwQNfNzdtgAAACASURBVDjL5c5bE4FQGIXhnVQOLcIO5VAKOVRC//+3MV3txah7vfNdPjPf
EkKrTzQC7b9AR+8aZpXV6w++wdCG0mj8CRxgMp1J6b5vvlgCHgN/hbX6eINtQCDELlKnxXscCByR
8PgTzgRMXBhckRLIkDO4tRMU9weDZ1kQ+F1LwAuy3RCj+B29OAAAACV0RVh0ZGF0ZTpjcmVhdGUA
MjAyMC0wOS0wMlQxMjoxNjo1MyswMzowMA8BnuEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDkt
MDJUMTI6MTY6NTMrMDM6MDB+XCZdAAAAAElFTkSuQmCC" />
</svg>
`]
