import Cryptr from 'cryptr';


/*** COLOR FUNCTIONS ***/
    export function numberToColour(number) {
      const b= (number & 0xff0000) >> 16;
      const g = (number & 0x00ff00) >> 8;
      const r = (number & 0x0000ff);
      return [r,g,b];
      // or eg. return `rgb(${r},${g},${b})`;
    }
    export function getHexColor(number){
        return "#"+((number)>>>0).toString(16).slice(-6);
      }
    export function getTextColor(intColor) {
        let rgb=numberToColour(intColor);
        var o = Math.round(((parseInt(rgb[0]) * 299) +
                            (parseInt(rgb[1]) * 587) +
                            (parseInt(rgb[1]) * 114)) / 1000);
        var fore = (o > 125) ? 'black' : 'white';
        return fore;
      };


/*** STRING FUNCTIONS ****/

    export function escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    }
    export function replaceAll(str, find, replace) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }




/**** APP FUNCTIONS ****/

    export function convertVersionToPort(version){
        let v = replaceAll(version,".","");
        return v;
    }
    export function getUrlApi(){
        if(process.env.REACT_APP_API_PORT){
            return process.env.REACT_APP_API_URL +":"+process.env.REACT_APP_API_PORT;
        }else{
            return process.env.REACT_APP_API_URL +":"+(20000+parseInt(convertVersionToPort(process.env.REACT_APP_API_VS)));
        }
    }
    export function getUrlSite(){
        return process.env.REACT_APP_URL +":"+process.env.REACT_APP_PORT;
    }
    export function getUrlSiteNoPort(){
        return process.env.REACT_APP_URL ;
    }
    export function getDBCONF(){
        let DBConf={
            "DB_HOST":process.env.REACT_APP_DB_HOST,
            "DB_USER":process.env.REACT_APP_DB_USER,
            "DB_PASSWORD":process.env.REACT_APP_DB_PASSWORD,
            "DB_NAME":process.env.REACT_APP_DB_NAME,
            "DB_PORT":process.env.REACT_APP_DB_PORT
        };
        return encrypt(JSON.stringify(DBConf),process.env.REACT_APP_ENCRYPTDBINFO);
        //return JSON.stringify(DBConf);
    }

    
    export function getMENUCONF(MENUConf){
        if(MENUConf!==undefined && MENUConf!==null && MENUConf!==""){
           try{
            let MENUConfStr=decrypt(MENUConf,process.env.REACT_APP_ENCRYPTDBINFO);
            //let MENUConfStr=MENUConf;
            return MENUConfStr;

           }catch(er){
               return "";
           }
        }else{return "";}
    }
    export function setMENUCONF(MENUConf){
        let MENUConfStr=encrypt(MENUConf,process.env.REACT_APP_ENCRYPTDBINFO);
        //let MENUConfStr=MENUConf;
        return MENUConfStr;
    }

    export function encrypt(text, key) {
        let cryptr = new Cryptr(key);
        let encryptedString = cryptr.encrypt(text);
        //var crypt = new Crypt();
        //let encryptedString =crypt.encrypt(key,text);
        return encryptedString;
      }
      
    export function decrypt(encryptedText, key) {
        let cryptr = new Cryptr(key);
        let encryptedString = cryptr.decrypt(encryptedText);
        //var crypt = new Crypt();
        //let encryptedString =crypt.decrypt(key,encryptedText);
        //return encryptedString.message;
        return encryptedString;
      }
      export function findObjectByKey(array, key, value) {
          for (var i = 0; i < array.length; i++) {
              if (array[i][key] == value) {
                  return array[i];
              }
          }
          return null;
      }
      

      export function getMailtoUrl(to, subject, body) {
        var args = [];
        if (typeof subject !== 'undefined') {
            args.push('subject=' + encodeURIComponent(subject));
        }
        if (typeof body !== 'undefined') {
            args.push('body=' + body)
        }
    
        var url = 'mailto:' + encodeURIComponent(to);
        if (args.length > 0) {
            url += '?' + args.join('&');
        }
        return url;
    }
    
    export function inArray(comparer,array) { 
        for(var i=0; i < array.length; i++) { 
            if(comparer(array[i])) return true; 
        }
        return false; 
    }; 
    
    // adds an element to the array if it does not already exist using a comparer 
    // function
    export function pushIfNotExist (element, comparer, array) { 
        if (!inArray(comparer,array)) {
            array.push(element);
        }
    }; 
    export function toTitleCase(str) {
        return str.replace(/\w\S*/g, function(txt){
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    export function  removeDuplicates( arr, prop ) {
        if(arr.length==0){return arr;}
        let obj = {};
        return Object.keys(arr.reduce((prev, next) => {
          if(!obj[next[prop]]) obj[next[prop]] = next; 
          return obj;
        }, obj)).map((i) => obj[i]);
      }
    
    export function  getTextFromJSONLink(link){
        if(link!==null & link!==undefined){
            
            try{
                let JSONlink= JSON.parse(link);
                if(JSONlink.valore){
                  return JSONlink.valore;
                }
                if(JSONlink.value){
                  return JSONlink.value;
                }
              }catch(ex){
                return undefined;
              }
        }else{
            return undefined;

        }
        
    }
    export function  getDataTypeFromJSONLink(link){
        if(link!==null & link!==undefined){
            
            try{
              let JSONlink= JSON.parse(link);
              return JSONlink.dataType;
            }catch(ex){
              return undefined;
            }
        }else{
            return undefined;

        }
        
    }
    export function bufferToBase64(buf) {
        var binstr = Array.prototype.map.call(buf, function (ch) {
            return String.fromCharCode(ch);
        }).join('');
        return btoa(binstr);
    }
    export function base64ArrayBuffer(arrayBuffer) {
        var base64    = ''
        var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
      
        var bytes         = new Uint8Array(arrayBuffer)
        var byteLength    = bytes.byteLength
        var byteRemainder = byteLength % 3
        var mainLength    = byteLength - byteRemainder
      
        var a, b, c, d
        var chunk
      
        // Main loop deals with bytes in chunks of 3
        for (var i = 0; i < mainLength; i = i + 3) {
          // Combine the three bytes into a single integer
          chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
      
          // Use bitmasks to extract 6-bit segments from the triplet
          a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
          b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
          c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
          d = chunk & 63               // 63       = 2^6 - 1
      
          // Convert the raw binary segments to the appropriate ASCII encoding
          base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
        }
      
        // Deal with the remaining bytes and padding
        if (byteRemainder == 1) {
          chunk = bytes[mainLength]
      
          a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2
      
          // Set the 4 least significant bits to zero
          b = (chunk & 3)   << 4 // 3   = 2^2 - 1
      
          base64 += encodings[a] + encodings[b] + '=='
        } else if (byteRemainder == 2) {
          chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
      
          a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
          b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4
      
          // Set the 2 least significant bits to zero
          c = (chunk & 15)    <<  2 // 15    = 2^4 - 1
      
          base64 += encodings[a] + encodings[b] + encodings[c] + '='
        }
        
        return base64
      }
      export  function decodeArrayBuffer (buffer) {
        var mime;
        var a = new Uint8Array(buffer);
        var nb = a.length;
        if (nb < 4)
            return null;
        var b0 = a[0];
        var b1 = a[1];
        var b2 = a[2];
        var b3 = a[3];
        if (b0 == 0x89 && b1 == 0x50 && b2 == 0x4E && b3 == 0x47)
            mime = 'image/png';
        else if (b0 == 0xff && b1 == 0xd8)
            mime = 'image/jpeg';
        else if (b0 == 0x47 && b1 == 0x49 && b2 == 0x46)
            mime = 'image/gif';
        else
            return null;
        var binary = "";
        for (var i = 0; i < nb; i++)
            binary += String.fromCharCode(a[i]);
        var base64 = window.btoa(binary);
        return 'data:' + mime + ';base64,' + base64;
    }
    export function getPrimaryColor(){
        return process.env.REACT_APP_CONF_FIRSTCOLOR?process.env.REACT_APP_CONF_FIRSTCOLOR:"#00A09B";
    }
    export function eItalic(x){
       
        if(x!==undefined && x!==null){  
            return x.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
        }else{
            return x;
        }
      }
    export function MergeRecursive(obj1, obj2) {
 
         for (var p in obj2) {
           try {
             // Property in destination object set; update its value.
             if ( obj2[p].constructor==Object ) {
               obj1[p] = MergeRecursive(obj1[p], obj2[p]);
       
             } else {
                
               obj1[p] = obj2[p];
       
             }
       
           } catch(e) {
             // Property in destination object not set; create it and set its value.
             obj1[p] = obj2[p];
       
           }
         }
         return obj1;
    }
