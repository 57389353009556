
import React, { Component } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CRow,
  CFormLabel,
  CFormText,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import logo from '../../../assets/img/brand/logo.png'

class Register extends Component {
  render() {
    return (

<div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12" lg="7" xl="12">
            <CCard className="mx-12">
              <CCardBody className="p-4">
             


                <CForm action="" method="post" >
                <img src={logo} className="img-fluid center-block" style={{"width":"300px"}}  alt="Logo Aziendale"/>
                  
                   <h2>Le tue dichiarazioni doganali sono pronte per le nuove modalita’ previste da ADM?</h2>   
                  
                  <p className="lead">Registrati e prova gratuitamente la firma digitale e l’invio al portale <b>ADM</b></p>
                   
                  
              <CForm>
                <CFormLabel htmlFor="company">Ragione Sociale*</CFormLabel>
                <CFormInput id="company" placeholder="Inserisci la tua ragione sociale" required />
              </CForm>
              <CForm>
                <CFormLabel htmlFor="vat">Partita Iva*</CFormLabel>
                <CFormInput id="vat" placeholder="IT1024514554" required />
              </CForm>
              <CForm>
                <CFormLabel htmlFor="street">Indirizzo</CFormLabel>
                <CFormInput id="street" placeholder="Inserisci il tuo indirizzo"  required/>
              </CForm>
              <CForm row className="my-0">
                <CCol xs="3">
                  <CForm>
                    <CFormLabel htmlFor="postal-code">Cap</CFormLabel>
                    <CFormInput id="postal-code" placeholder="Cap" />
                  </CForm>
                </CCol>
                <CCol xs="7">
                  <CForm>
                    <CFormLabel htmlFor="city">Località</CFormLabel>
                    <CFormInput id="city" placeholder="Località" />
                  </CForm>
                </CCol>
                <CCol xs="2">
                  <CForm>
                    <CFormLabel htmlFor="postal-code">Provincia</CFormLabel>
                    <CFormInput id="postal-code" placeholder="Provincia" />
                  </CForm>
                </CCol>
              </CForm>
              <CForm>
                <CFormLabel htmlFor="email">Email*</CFormLabel>
                <CFormInput id="email" placeholder="Email" autoComplete="email" />
                <CFormText>Questa email verrà utilizzata per   l'invio delle credenziali di accesso alla piattaforma TruckOne WEB</CFormText>
              </CForm>
              <CForm>
                <CFormLabel htmlFor="Telefono">Telefono</CFormLabel>
                <CFormInput id="Telefono" placeholder="Telefono" autoComplete="Telefono" />
              </CForm>
              <CForm>
                <CFormLabel htmlFor="Password">Password Certificato*</CFormLabel>
                <CFormInput id="Password" type="password"  placeholder="Password Certificato" autoComplete="Password" />
                    <CFormText>Inserisci la password utilizzate per generare il certificato</CFormText>
              </CForm>
              <CForm>
                <CFormLabel htmlFor="file-input">File Certificato*</CFormLabel>
                <CFormInput id="file-input" name="file-input" type="file"/>
                    <CFormText>Inserisci il tuo certificato. Non sai come procedere? contattaci alla mail <a href="#">info@truck-one.it</a></CFormText>
              </CForm>
                
              
              <CForm>
                <CFormLabel htmlFor="note">Altre informazioni </CFormLabel>
                <CFormTextarea 
                      name="note" 
                      id="note" 
                      rows="5"
                      placeholder="Note..." 
                    />
                  <CFormText>Inserisci eventuali altre informazioni, in modo da aiutarti al meglio</CFormText>
                </CForm>
                <CForm row className="my-0">
                    <CCol xs="4">
                    <CForm>
                  <CButton color="success" block>Crea il tuo account</CButton>
                  
                </CForm>
                </CCol>
                </CForm>

                  


                   
                <CModal color='success ' visible={false} >
                        <CModalBody>
                          <img src={logo} className="img-fluid center-block" style={{"width":"300px", "margin":"0 auto","display":"block"}}  alt="Logo Aziendale"/>
                        <h1>
                          La tua registrazione si è conclusa con successo!
                        </h1>
                        <p>
                          Controlla la tua casella email. Ti abbiamo inoltrato le indicazioni per accedere alla piattaforma.
                        </p>
                        </CModalBody>
                        <CModalFooter>
                            <CButton color="success" >OK</CButton>
                            
                        </CModalFooter>
                        </CModal>


                </CForm>
              </CCardBody>
              <CCardFooter className="p-4">
              <p>Se non sai come creare il certificato o generare i vari file xml contattaci direttamente alla mail <a href="#">info@truck-one.it</a>.</p>
               <p>     Oppure clicca il bottone sottostante!</p>
                <CForm row className="my-0">
                    <CCol xs="4">
                    <CForm>
                  <CButton color="success" block>
                    <CIcon name="cil-send" />Contattaci
                  </CButton>
                </CForm>
                </CCol>
                </CForm>
                <p>In collaborazione con <a href="#">Projecta</a></p>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>

    );
  }
}

export default Register;