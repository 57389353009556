import React, { Component } from 'react';
import {getUrlApi,getDBCONF} from "../../../helpers/Helpers";
import queryString from 'query-string';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class Report extends Component {
  
  constructor(props){
    super(props);
    
   }
   getHTMLData = async() =>{
    let token =  cookies.get('JEProjectJWTTK'); 
    const values = queryString.parse(this.props.location.search);
    let parameters=JSON.parse(decodeURI(values.pars));
    let nomeRapportino=values.reportName;
    let printdetails=JSON.parse(decodeURI(values.printdetails));
    const self = this;
    let body={
      parameters:parameters,
      nomeRapportino:nomeRapportino,
      conf:getDBCONF(),
      details:printdetails,
      token:token,
    };
    
    let response = await fetch(getUrlApi()+"/api/getHtmlTemplate", {
      method: 'POST',
      body:    JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    }).then(function(response) {
        if(response.ok) {
          return response.blob();
        }
        throw new Error('Network response was not ok.');
      }).then(function(myBlob) { 
        const file = new Blob([myBlob], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.location.href =fileURL;
        
      })
      .catch(function(error) {
      }); 
      return response;
 


  };
  render() {
    this.getHTMLData();
    
    return (
      <div className="app">
      
      </div>
    );
  }
}

export default Report;