import React, { Component } from 'react';
import { CButton, CCard, CCardBody, CCol, CContainer, CFormInput, CInputGroup, CRow, CForm,CInputGroupText } from '@coreui/react';
import {Redirect} from 'react-router-dom';
import Cookies from 'universal-cookie';
import {getUrlApi,getDBCONF,setMENUCONF,getMENUCONF} from "../../../helpers/Helpers";
import logo from '../../../assets/img/brand/logo.png'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas,fab);

const cookies = new Cookies();
class Login extends Component {
  
  constructor(props){
    super(props);
    this.state={
    username:'',
    password:'', 
    redirectToReferrer: (cookies.get('JEProjectJWTTK') !== undefined),
    result:'',
    usernameErrato:false,
    passwordErrata:false
    }
    this.updateUsernameValue = this.updateUsernameValue.bind(this);
    this.updatePwdValue = this.updatePwdValue.bind(this);
   }
   handleLogin(event){
    this.callLogin();
  
   }
   handleSubmit(event) {
    event.preventDefault();
  }
  
   callLogin= async() =>{
    const self = this;
    var usernameStr=self.state.username;
    var passwordStr=self.state.password;
    let confLogin = JSON.stringify({"username":usernameStr,"password":passwordStr});
    let criptata =setMENUCONF(confLogin);
    let decript=getMENUCONF(criptata);

    var body={
      strLogin:criptata,
      conf:getDBCONF()
    };
    
    const response = await fetch(getUrlApi()+'/api/login', {
      method: 'POST',
      body:    JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
      
      }).then((results) => results.json()) 
      .then(function(json) {
        if(json.error){
          self.setState({
              result: json.message,
            
                        });
        }else{
          const cookies = new Cookies();
          cookies.set('JEProjectJWTTK', json.token, { path: '/', maxAge:60*60*24 });
          self.setState({
            redirectToReferrer: true
          })
          
        }
        
        //self.checkLogin();
      })
      .catch(function(error) {
        self.setState({result: "errore in fase di login"});
      }); 
      return response;
  // 
  };
  updateUsernameValue(evt) {
    this.setState({
      username: evt.target.value
    });
  }
  updatePwdValue(evt){
    this.setState({
      password: evt.target.value
    });
  }
  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state

    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }
    return (
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer className="">
          <CRow className="justify-content-center">
            <CCol md="6" lg="4">
                <CCard>
                  <CCardBody >
                    <CForm onSubmit={(event) => this.handleSubmit(event)} >
                    <CRow className="justify-content-center">
                      <CCol xs="8">
                      <img src={logo} className="img-fluid center-block" style={{"width":"100%"}} alt="Logo Aziendale"/>
                      </CCol>
                    </CRow>
                    <p className="text-muted text-center">Accedi al tuo account</p>
                    <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <FontAwesomeIcon icon={["fas", "user"]} />
                          </CInputGroupText>
                      
                      
                      <CFormInput 
                      type="text" 
                      placeholder="Username" value={this.state.username} 
                      onChange={this.updateUsernameValue} 
                      />

                      
                    </CInputGroup>
                      
                    <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <FontAwesomeIcon icon={["fas", "lock"]} />
                        </CInputGroupText>
                      <CFormInput type="password" placeholder="Password" onChange={this.updatePwdValue}/>
                    </CInputGroup>
                    <CRow className="justify-content-center" >
                      <CCol xs="6" className="d-grid gap-25">
                        <CButton /*block*/ color="primary" type="submit" className=""  onClick={(event) => this.handleLogin(event)} >Login</CButton>
                      </CCol>
                      
                    </CRow>
                    <CRow>
                      <CCol xs="12">
                      <p className="text-muted text-center">{this.state.result}</p>
                      </CCol>
                    </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default Login;